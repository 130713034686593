@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

:root {
  --color-heading: #2a186e;
}

.heading {
  color: var(--color-heading);
  font-weight: 700;
}

.button {
  border-radius: 30px;
  background-color: var(--color-heading);
  padding: 10px 40px;
  color: white;
  border: none;
}
.button a {
  text-decoration: none;
  color: white;
}

.latestNews {
  background: #f2f2f2;
  padding: 3rem;
}

.breadcrump {
  background-image: url(./Images/breadcrump.jpeg);
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.breadcrumbOverlay{
  background: #000000bd;
  padding: 4rem 4rem;
  color: white;
}

.breadcrumbOverlay a {
  color: white;
  text-decoration: none;
}

.contentWidth{
  max-width: 70%;
}

.contentWidth h2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width:768px) {
  .breadcrumbOverlay{
    padding: 2rem 1rem;
  }
  .contentWidth{
    max-width: 100%;
  }
  
}