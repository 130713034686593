.newsSection{
    padding: 5px;;
    background-color: white;
    margin-bottom: 0.8rem;
    align-items: center;
}

.newsBigImage{
    background-color: white;
    padding: 1rem;
    height: 100%;
}
