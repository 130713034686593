.navbar-brand img {
  height: 100px;
  width: auto;
}

.navbar {
  background-color: white;
  padding: 1rem;
  border: 5px 10px solid white;
}
.nav-link {
  background-color: var(--color-heading);
  color: white !important;
  font-weight: 600;
  border-radius: 5px;
}
.navbar-nav{
  gap: 20px;
}
.navbar-collapse {
  justify-content: center;
}

.navbar-toggler{
  border: 1px solid white;
  outline: none !important;
  background-color: var(--color-heading);
  box-shadow: none !important;
}

.navbar_menu{
  display: flex !important;
  justify-content: end !important;
}

@media only screen and (max-width: 768px) {
  .navbartitle{
    font-size: 12px;
  }
  .navbar-nav{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .nav-link{
    padding: 5px 10px;
  }
  .navbar-nav{
    gap: 5px;
  }
  .navbar {
    padding: 0rem;
  }
  .homeTopheading {
    font-size: 16px;
    margin-bottom: 0;
  }
  .navbar-brand img {
    height: 60px;
    width: auto;
  }
  .menuIcon{
    display: flex;
    width: 100%;
    justify-content: end;
  }

}
