.nimtExperienced{
    display: flex;
    justify-content: space-around;
}

.nimtExperienced i{
    font-size: 25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: var(--color-heading);
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:768px) {
    .welcome_Nimt{
        margin-top: 0rem;
    }
    
}