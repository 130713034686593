footer {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1; 
  padding-top: 5rem;
  padding-bottom: 2rem;
}

footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../Images/footer-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: -1; 
}

footer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.845); 
  z-index: -1; 
}

footer .social-icons a {
  font-size: 18px;
}

footer .social-icons a:hover {
  color: #f9c74f; 
}

footer h5 {
  font-weight: 700;
  margin-bottom: 1rem;
}

footer ul {
  padding: 0;
  list-style: none;
}

footer ul li {
  margin-bottom: 0.5rem;
}

footer ul li a {
  color: #ffffff;
  text-decoration: none;
}

footer ul li a:hover {
  text-decoration: underline;
}

footer .row .col-md-4 img {
  width: 100%;
  border-radius: 4px;
}

footer .form-control {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
}

footer .form-control:focus {
  background: none;
  border-color: #f9c74f;
  box-shadow: none;
}

footer .btn-primary {
  background-color: #f9c74f;
  border: none;
}

footer .btn-primary:hover {
  background-color: #e0a800;
}

.footertopSection {
  position: relative;
  bottom: -54px;
  z-index: 9;
  border-radius: 5rem;
  padding: 2rem;
  color: white;
  background-color: var(--color-heading);
}


@media only screen and (max-width:768px) {
  .footertopSection{
    border-radius: 1rem;
  }
}